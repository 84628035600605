import React from "react";
import SEO from "components/seo";
import {
  ContactForm,
  ContentPhoto,
  FullPhoto,
  Header,
  Hero,
  Layout,
  Navigation,
  TextBox,
  WhatWeDid,
  StickyColumn,
} from "components/CaseLayout";
import { ARTPROGRES } from "shared/Types/cases";
import { useCaseData } from "shared/Hooks/CasesNew/useCaseData";

const Artprogres = () => {
  const data = useCaseData(ARTPROGRES);

  return (
    <Layout>
      <SEO title={data.title} description={data.seo}/>
      <Hero data={data.hero}/>
      <Navigation type={ARTPROGRES}/>
      <WhatWeDid data={data.what_we_did}/>
      <Header data={data.headers.first}/>
      <FullPhoto data={data.full_images.first}/>
      <TextBox color="#fff" background="#ABC539" data={data.text_boxes.first}/>
      <ContentPhoto data={data.content_images.first}/>
      <TextBox data={data.text_boxes.second}/>
      <StickyColumn color={data.color} data={data.sticky.first}/>
      <StickyColumn color={data.color} reverse={1} data={data.sticky.second}/>
      <FullPhoto data={data.full_images.second}/>
      <Header data={data.headers.second}/>
      <TextBox data={data.text_boxes.third}/>
      <ContentPhoto data={data.content_images.second}/>
      <TextBox data={data.text_boxes.fourth}/>
      <FullPhoto data={data.full_images.third}/>
      <ContactForm/>
    </Layout>
  );
};

export default Artprogres;
